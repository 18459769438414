import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import App from '../App';
//import Ask from "../Ask";
import QuestionWithRetrieval from "../QuestionWithRetrieval";
import Question from "../../containers/Question";
//import Header from "../../components/Header";
//import App from "../../containers/App";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<QuestionWithRetrieval />} /> 
        <Route path="projects/:idRetrieval/chats/:id" element={<Question />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
