const retrieval = {
  retrievalId: process.env.REACT_APP_RETRIEVAL,
};

const componentColors = {
  changeTopic: {
    backgroundColor: "#cf2e2e",
    textColor: "white",
    borderColor: "#cf2e2e",
    hoverColor: "#00008b",
  },
  matrixMode: {
    backgroundColor: "#006400",
    textColor: "white",
    borderColor: "#008000",
    hoverColor: "#008000",
  },
  feedback: {
    backgroundColor: "#cf2e2e",
    textColor: "white",
    borderColor: "#cf2e2e",
    hoverColor: "#00008b",
  },
  like: {
    backgroundColor: "#cf2e2e",
    textColor: "white",
    borderColor: "#cf2e2e",
    hoverColor: "#228b22",
  },
  dislike: {
    backgroundColor: "#cf2e2e",
    textColor: "white",
    borderColor: "#cf2e2e",
    hoverColor: "#B20000",
  },
  outline: {
    backgroundColor: "white",
    textColor: "#cf2e2e",
    borderColor: "#cf2e2e",
    hoverColor: "#f2f2f2",
  },
  arrowColor: "#f2f2f2",
  assistantTextColor: "text-gray-800",
  userTextColor: "text-gray-800",
};

const parameters = {
  componentColors,
  retrieval,
};

export default parameters;
