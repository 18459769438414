import api from '../utils/api';

export const addMessage = async (question, chat) => {
  const endpoint = `cognitive/chats/${chat}/`;
  const resp = await api.post(endpoint, {message: question});
  return resp;
};

const MessagesApi = {
  addMessage,
};

export default MessagesApi;