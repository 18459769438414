/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getChat } from "../api/QuestionsApi";
import Display from "../components/Display";

import ShowQuestion from "../submodule/src/App";

import { getRetrieval } from "../api/RetrievalsApi";
import {
  addQuestion,
  addFeedback,
  addFeedback2,
  matrixMode,
} from "../api/QuestionsApi";

import { addMessage } from "../api/MessagesApi";
import logo from "../logotipo_ggi.jpg";
import parameters from "../utils/parameters";

const Question = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState("");
  const [error, setError] = useState(null);

  const otherReferences = false;

  const selectedQuestion = async () => {
    try {
      const chat = await getChat(id);
      setChat(chat);
    } catch (error) {
      console.log(error.response.status);
      if (error.response.status === 404) {
        setError("Chat not found");
      } else {
        setError("An error occured");
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    selectedQuestion();
  }, []);

  return (
    <Display
      loading={loading}
      error={error}
      center={
        <ShowQuestion
          getRetrieval={getRetrieval}
          retrievalId={id}
          addQuestionRetData={addQuestion}
          addMessage={addMessage}
          logo={logo}
          chat={chat}
          otherRefs={otherReferences}
          addFeedback={addFeedback}
          addFeedback2={addFeedback2}
          newApi={true}
          matrix_mode={true}
          addMatrixMode={matrixMode}
          colors={parameters.componentColors} //new
        />
      }
    />
  );
};

export default Question;
