//import {componentColors, retrieval} from "../utils/parameters";
import parameters from "../utils/parameters";
import Display from "../components/Display";

import ShowQuestion from "../submodule/src/App";

import { getRetrieval } from "../api/RetrievalsApi";
import {
  addQuestion,
  addFeedback,
  addFeedback2,
  matrixMode,
} from "../api/QuestionsApi";

import { addMessage } from "../api/MessagesApi";
import logo from "../logotipo_ggi.jpg";

const QuestionWithRetrieval = () => {
  const otherReferences = false;

  return (
    <Display
      loading={false}
      error={null}
      center={
        <ShowQuestion
          getRetrieval={getRetrieval}
          retrievalId={parameters.retrieval.retrievalId}
          addQuestionRetData={addQuestion}
          addMessage={addMessage}
          logo={logo}
          otherRefs={otherReferences}
          addFeedback={addFeedback}
          addFeedback2={addFeedback2}
          newApi={true}
          matrix_mode={false}
          addMatrixMode={matrixMode}
          colors={parameters.componentColors}
        />
      }
    />
  );
};

export default QuestionWithRetrieval;
